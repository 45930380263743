import React, { useState, useEffect } from 'react';

const GrafanaChart = ({ source, width, height }) => {
  const [authToken, setAuthToken] = useState(() => {
    // Retrieve authToken from localStorage when the component mounts
    const token = localStorage.getItem('authToken');
    return token ? JSON.parse(token) : null;
  });

  useEffect(() => {
    // Update authToken when it changes in localStorage
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthToken(JSON.parse(token));
    }
  }, []);

  const modifiedSource = authToken ? `${source}&auth_token=${authToken.access_token}` : source;

  console.log("modifiedSource", modifiedSource)

  return (
    <div>
      {/* Use the source directly without appending the token in the URL */}
      <iframe title="My Grafana Dashboard" src={modifiedSource} width={width} height={height} frameBorder="0"></iframe>
    </div>
  );
};

export default GrafanaChart;