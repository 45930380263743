import React from "react";
import GrafanaChart from './GrafanaChart';
import NavigationBar from "./NavigationBar";

const Latency = () => {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td valign={"top"}>
              <NavigationBar/>
            </td>
            <td>
              &nbsp;&nbsp;&nbsp;
            </td>
            <td>
              <h2>Latency</h2>
              <table>
                <tbody>
                    <tr>
                      <td>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&theme=light&panelId=10&kiosk"} width={750} height={300}/>
                      </td>
                      <td>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&theme=light&panelId=40&kiosk"} width={750} height={300}/>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&theme=light&panelId=8&kiosk"} width={750} height={300}/>
                      </td>
                      <td>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&theme=light&panelId=46&kiosk"} width={750} height={300}/>
                      </td>
                    </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Latency;