import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check if authentication token exists in localStorage
    const token = localStorage.getItem('authToken');
    return !!token; // Convert token to boolean
  });

  useEffect(() => {
    // Check if authentication token exists in localStorage
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = (token) => {
    setIsAuthenticated(true);

    console.log("Storing token in localStorage", token);

    console.log("Storing token in localStorage JSON", JSON.stringify(token));

    localStorage.setItem('authToken', JSON.stringify(token));
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('authToken');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
