import React, { useState, useContext } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleLogin = async () => {
    try {
      const data = new URLSearchParams();
      data.append('username', username);
      data.append('password', password);

      const response = await axios.post('https://service.pipeworks.kdsc.co.uk/api/token', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      console.log(response.data);
      login(response.data);
      navigate('/prices');
    } catch (error) {
      setErrorMessage("Invalid username or password. Please try again."); // Set error message
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <form>
        <table>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <h1>Pipeworks</h1>
              </td>
              <td style={{ textAlign: "right", verticalAlign: "middle", margin: 0, padding: 0 }}>
                <img src="/logo.jpg" alt="KDSC limited" height="70" />
              </td>
            </tr>
            <tr>
              <td>
                <label>Username:</label>
              </td>
              <td>
                <input
                  type="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>Password:</label>
              </td>
              <td>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </td>
            </tr>
            {errorMessage && (
              <tr>
                <td colSpan={2} style={{ color: 'red', textAlign: 'center' }}>
                  {errorMessage}
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={2} align={"right"}>
                <button type="button" onClick={handleLogin}>
                  Login
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default LoginForm;