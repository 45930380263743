import React, {useContext} from "react";
import { Navigate } from "react-router-dom";
import {AuthContext} from "./AuthContext";

const ProtectedRoute = ({ element: Element, ...rest }) => {

  const { isAuthenticated } = useContext(AuthContext);

  console.log("ProtectedRoute", isAuthenticated);
  return isAuthenticated ? <Element /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
