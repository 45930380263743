import React from "react";
import GrafanaChart from './GrafanaChart';
import NavigationBar from "./NavigationBar";

const Recorders = () => {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td valign={"top"}>
              <NavigationBar/>
            </td>
            <td>
              &nbsp;&nbsp;&nbsp;
            </td>
            <td>
              <h2>Recorders</h2>
              <table>
                <tbody>
                    <tr>
                      <td>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&theme=light&panelId=2&kiosk"} width={1500} height={600}/>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&theme=light&panelId=4&kiosk"} width={1500} height={600}/>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&theme=light&panelId=24&kiosk"} width={1500} height={600}/>
                      </td>
                    </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Recorders;