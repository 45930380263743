import React from 'react';
import { Link } from 'react-router-dom';

const NavigationBar = () => {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <img src="/logo.jpg" alt="KDSC limited" height="70" />
          </td>
        </tr>
        <tr>
          <td>
            &nbsp;<Link to="/prices">Prices</Link>
          </td>
        </tr>
        <tr>
          <td>
            &nbsp;<Link to="/cluster">Cluster</Link>
          </td>
        </tr>
        <tr>
          <td>
            &nbsp;<Link to="/recorders">Recorders</Link>
          </td>
        </tr>
        <tr>
          <td>
            &nbsp;<Link to="/combiners">Combiners</Link>
          </td>
        </tr>
        <tr>
          <td>
            &nbsp;<Link to="/latency">Latency</Link>
          </td>
        </tr>
        <tr>
          <td>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td>
            &nbsp;<Link to="/logout">Logout</Link>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default NavigationBar;