import React from "react";
import GrafanaChart from './GrafanaChart';
import NavigationBar from "./NavigationBar";

const Prices = () => {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td valign={"top"}>
              <NavigationBar/>
            </td>
            <td>
              &nbsp;&nbsp;&nbsp;
            </td>
            <td>
              <h2>Prices</h2>
              <table>
                <tbody>
                    <tr>
                      <td><GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&panelId=30&theme=light&kiosk"} width={500} height={250}/></td>
                      <td><GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&panelId=35&theme=light&kiosk"} width={500} height={250}/></td>
                      <td><GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d-solo/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&panelId=36&theme=light&kiosk"} width={500} height={250}/></td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&viewPanel=32&theme=light&kiosk"} width={1500} height={600}/>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&viewPanel=33&theme=light&kiosk"} width={1500} height={600}/>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <GrafanaChart source={"https://grafana.pipeworks.kdsc.co.uk/d/QZuTSy-Sz/pipeworks?orgId=1&refresh=1s&viewPanel=34&theme=light&kiosk"} width={1500} height={600}/>
                      </td>
                    </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Prices;